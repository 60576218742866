<template>
  <div>
    <!-- 会员卡默认套餐 -->
    <base-table
      ref="shopRef"
      :columnsData="columns"
      :tableData="normalData"
      rowKey="key"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">钱包充值 默认套餐</h4>
        </div>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerAreaEdit(record)">编辑</a-button>
      </template>
    </base-table>

    <!-- 区域套餐 -->
    <base-table
      class="table-class"
      ref="shopRef"
      :columnsData="columns"
      :tableData="areaData"
      rowKey="key"
      style="margin-top:20px"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">钱包充值 区域套餐</h4>
          <div>
            <a-button class="ml-3" type="primary" @click="handlerAreaAdd" html-type="submit">新增</a-button>
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { getRechargeList, deleteRecharge } from "@/api/activity/vip-card.js"
import { normalColumns, areaColumns, normalRowKey, areaRowKey } from "./columns"
import { rowSpan } from "@/utils/index"
export default {
  data() {
    return {
      normalRowKey: normalRowKey,
      areaRowKey: areaRowKey,
      normalColumns: normalColumns,
      areaColumns: areaColumns,
      normalData: [],
      areaData: [],
      columns: [
        {
          title: "套餐名称",
          dataIndex: "name",
          align: "center",
          width: "15%",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.delivery_regionRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "覆盖区域",
          align: "center",
          dataIndex: "delivery_region",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.delivery_regionRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "充值金额",
          align: "center",
          dataIndex: "denomination",
          width: "10%",
        },
        // {
        //   title: "售价",
        //   align: "center",
        //   dataIndex: "price",
        //   width: "10%",
        // },
        {
          title: "赠送积分",
          align: "center",
          dataIndex: "gift_integral",
          width: "10%",
        },
        {
          title: "赠送优惠券",
          align: "center",
          dataIndex: "gift_coupon",
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 140,
          customRender: (text, row) => {
            if(row.type == 1){
              return {
                children: (
                  <div>
                    <a-button class="p-0" type="link"
                      onClick={() => {
                        this.handlerAreaEdit(row)
                      }}
                    >编辑</a-button>
                  </div>
                ),
                attrs: {
                  rowSpan: row.delivery_regionRowSpan, //需要合并的数据
                },
              }
            }else{
              return {
                children: (
                  <div>
                    <a-button class="p-0" type="link"
                      onClick={() => {
                        this.handlerAreaEdit(row)
                      }}
                    >编辑</a-button>
                    <a-button class="p-0 ml-4" type="link"
                      onClick={() => {
                        this.handlerAreaDelete(row)
                      }}
                    >删除</a-button>
                  </div>
                ),
                attrs: {
                  rowSpan: row.delivery_regionRowSpan, //需要合并的数据
                },
              }
            }
          },
          slots: {
            customRender: "operation",
          },
        },
      ],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getRechargeList()
      if (code == 0) {
        let baseArr = []
        data.list.map((item, i) => {
          item.recharge_activity_list.map((el, j) => {
            let obj = item
                obj.key = i + "-" + j
                obj = { ...obj, ...el }
            delete obj.recharge_activity_list
            baseArr.push(obj)
          })
        })

        // 如果全国还没配置、默认一个（服务端返回recharge_activity_list空 然后前端过滤了），
        if(!baseArr.some(el=>el.type == 1)){
          let defaultItem = data.list.find(item=>item.type==1)
          baseArr.push(defaultItem)
        }

        let normalArr = baseArr.filter((item) => item.type == 1)
            normalArr = rowSpan(normalArr, "delivery_region")
        this.normalData = normalArr

        let areaArr = baseArr.filter((item) => item.type == 2)
            areaArr = rowSpan(areaArr, "delivery_region")
        this.areaData = areaArr
      }
    },
    
    handlerAreaAdd() {
      this.$router.push({
        path: "/area-manager",
      })
    },
    handlerAreaEdit(e) {
      this.$router.push({
        path: "/area-manager",
        query: { id: e.id },
      })
    },
    handlerAreaDelete(e) {
      if(e.type == 1){
        this.$message.warning("默认套餐不能删除")
        return
      }
      const _this = this
      this.$confirm({
        title: "提示",
        content: "确认要删除吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        async onOk() {
          const { code } = await deleteRecharge({
            id: e.id,
            status: -1,
          })
          if (code == 0) {
            _this.$message.success("删除成功")
          }
          _this.initData()
        },
        onCancel() {
          console.log("Cancel")
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>